
// 错误信息
const error = [
    {
        path: '*',
        name: '404',
        component: () => import('@/components/error/404'),
        meta: {
            name: '404',
        }
    },
    {
        path: '/',
        name: '首页',
        redirect: '/home',
        // component: error404,
        meta: {
            name: '首页',
        }
    }
]


//首页
const home = [
    {
        path: '/home',
        name: '/home',
        component: () => import('@/views/home'),
        meta: { title: '首页' }
    },
]

const product = [
    { 
        path: '/product',
        name: '/product',
        component: () => import('@/views/product'),
        meta: { title: '产品' }
    },

]

const Case = [
    { 
        path: '/case',
        name: '/case',
        component: () => import('@/views/case'),
        meta: { title: '案例' }
    },

]







export const routes = [...error,  ...home, ...product, ...Case]
