import Vue from 'vue'
import Router from 'vue-router'
import { routes } from '@/router/module/home'


Vue.use(Router)
let router = new Router({
    mode:'hash',
    routes: [...routes]
})

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router
