import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/router';
import store from "./store/index";
import less from 'less';
Vue.use(less);
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // 全局配置
  router.beforeEach((to, from, next) => {
      // Change doc title
      if(to.meta.title == '产品'){
        document.title = '产品-开发工具 开发平台 数据交换';
      }else if(to.meta.title == '案例'){
        document.title = '案例-异构数据库交换平台 企业管理软件';
      }
      else if(to.meta.title == '首页'){
        document.title = '首页-低代码 配置化 可视化';
      }
      next();
  })
}
}).$mount('#app')
