import Vue from "vue";
import Vuex from "vuex";
import Load from './modules/loading'
Vue.use(Vuex);
const opiton = {
  strict: true,
  modules: {
    Load,
  }
};
let vuex= new Vuex.Store(opiton)
export default  vuex;
