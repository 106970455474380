export default {
  namespaced: true,
  state: {
    el_:0,
    LOADING:false
  },
  mutations: {
    showLoading(state) {
      state.LOADING = true
    },
    hideLoading(state) {
      state.LOADING = false
    }
  },
  actions: {
    onLoading(context) {
      context.commit('showLoading')
    },
    endLoading(context) {
      context.commit('hideLoading')
    },
  }
}